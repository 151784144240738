<template>
  <div>
    <div v-if="isSkuExceptionModule">
      <b-card class="mb-4 border-0 font_avenir card_clientException">
        <b-spinner v-if="loading"></b-spinner>
        <template #header>
          <strong>
            {{ isSkuExceptionModule ? 'SKU distribution exceptions' : 'Ship Via exceptions' }}
          </strong>
          <div class="info-button-wrapper float-right">
            <div id="info-client-exceptions" v-b-hover="handleInfoHover">
              <b-icon v-if="isHovered" icon="info-circle-fill" variant="info" scale="2"></b-icon>
              <b-icon v-else icon="info-circle" scale="2"></b-icon>
            </div>
            <b-tooltip
              id="info-client-exceptions-tooltip"
              target="info-client-exceptions"
              triggers="hover"
              placement="left"
              custom-class="dark-tooltip">
              {{isSkuExceptionModule ? `Here you can configure SKU exceptions for specific clients.
                                          In case you want to send a specific SKU to members
          of a specific client you can do it here.`
              : `Here you can configure Ship Via exceptions for specific clients. In case you want to have a
            different shipment to members of a specific client you can do it here. `}}
            </b-tooltip>
          </div>
        </template>
        <b-row v-if="!loading" class="mt-3" style="margin-top:0 !important">
          <b-col class="d-flex align-items-center justify-content-end mr-4">
            <b-button variant="primary" to="/order-management/client-exception/new">
              <span id="txt_addButton">Add SKU Exception</span>
            </b-button>
          </b-col>
        </b-row>
        <div v-if="!loading" class="holder_table">
          <table class="table">
            <thead>
            <tr>
              <th id='exc_th_id' scope="col" class="hidden">ID</th>
              <th id='exc_th_name' scope="col" style="width: 10%">{{ isSkuExceptionModule ? 'Product type' : 'Ship Via'}}</th>
              <th v-if="isShipViaExceptionModule" id='exc_th_name' scope="col" style="width: 10%">Method</th>
              <th id='exc_th_sku' scope="col" style="width: 10%" v-if="isSkuExceptionModule">SKU Distributions</th>
              <th id='exc_th_clients' scope="col" style="width: 38%">Clients</th>
              <th id='exc_th_descriptions' scope="col" style="width: 32%">Description</th>
              <th id='exc_th_options' scope="col">Options</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="cex in exceptions" :key="cex.cexId">
              <td class="hidden" scope="row">{{ cex.cexId }}</td>
              <td style="width: 10%">{{ cex.productType }}</td>
              <td v-if="isShipViaExceptionModule">{{translateShipViaMethod(cex.shipViaMethod)}}</td>
              <td class="exc_td_sku" style="width: 18%" v-if="isSkuExceptionModule">
                <div v-for="cl in cex.skuDistributions" :key="cl.product_type_sku_id" class="div_clex_name">
                  <div class="align-left clex_name">({{ cl.product_type_sku.sku}} -
                    {{ cl.product_type_sku.distribution }}% - {{ cl.product_type_sku.stock }} )</div>
                </div>
              </td>
              <td>
                <div class="holder_div_clex_name">
                  <div v-for="cl in cex.displayedClients" :key="cl.clId" class="div_clex_name">
                    <div  class="align-left clex_name">
                      {{ cl.name }}
                    </div>
                  </div>
                  <b-badge variant="primary"
                           v-if="cex.clients.length > limitDisplayedClients"
                           size="sm"
                           @click="selectItem(cex.clients)"
                           v-b-modal.modal-details>Show more
                  </b-badge>
                </div>
              </td>
              <td style="width: 40%">{{ cex.description }}</td>
              <td class="d-flex">
                <b-button v-b-tooltip.hover
                          title="Edit exception"
                          size="sm"
                          variant="outline-primary"
                          class="exception-action mr-2"
                          @click="editException(cex.cexId)">
                  <feather type="edit-2"></feather>
                </b-button>
                <b-button :data-testid="'exc_delBtn-'+cex.cexId"
                          v-b-tooltip.hover
                          title="Delete exception"
                          size="sm"
                          variant="danger"
                          class="exception-action"
                          @click="deleteException(cex.cexId)">
                  <feather type="trash-2"></feather>
                </b-button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </div>
    <div v-else>
      <div class="bs-callout bs-callout-info">
        <p>Configure Ship Via exceptions for specific clients and methods. In case you want to have a
          different shipment to members of a specific client you can do it here. </p>
      </div>
      <div v-if="!loading" class="holder_table">
          <table class="table">
            <thead>
            <tr>
              <th id='exc_th_id' scope="col" class="hidden">ID</th>
              <th id='exc_th_name' scope="col" style="width: 12%">{{isSkuExceptionModule ? 'Product type' : 'Ship Via'}}</th>
              <th v-if="isShipViaExceptionModule" id='exc_th_name' scope="col" style="width: 10%">Method</th>
              <th id='exc_th_sku' scope="col" style="width: 10%" v-if="isSkuExceptionModule">SKU Distributions</th>
              <th id='exc_th_clients' scope="col" style="width: 38%">Clients</th>
              <th id='exc_th_units' scope="col" style="width: 10%">Units</th>
              <th id='exc_th_descriptions' scope="col" style="width: 20%">Description</th>
              <th id='exc_th_options' scope="col">Options</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="cex in exceptions" :key="cex.cexId">
              <td class="hidden" scope="row">{{ cex.cexId }}</td>
              <td>{{ cex.productType }}</td>
              <td v-if="isShipViaExceptionModule">
                <div class="align-left clex_name" v-for="(method, index) in cex.shipViaMethods" :key="index">
                  {{ translateShipViaMethod(method) }}
                </div>
              </td>
              <td class="exc_td_sku" v-if="isSkuExceptionModule">
                <div v-for="cl in cex.skuDistributions" :key="cl.product_type_sku_id" class="div_clex_name">
                  <div class="align-left clex_name">({{ cl.product_type_sku.sku}} -
                    {{ cl.product_type_sku.distribution }}% - {{ cl.product_type_sku.stock }} )</div>
                </div>
              </td>
              <td>
                <div class="holder_div_clex_name">
                  <div v-for="cl in cex.displayedClients" :key="cl.clId" class="div_clex_name">
                    <div  class="align-left clex_name">{{ cl.name }}</div>
                  </div>
                  <b-badge variant="primary"
                           v-if="cex.clients.length > limitDisplayedClients"
                           size="sm"
                           @click="selectItem(cex.clients)"
                           v-b-modal.modal-details>Show more
                  </b-badge>
                </div>
              </td>
              <td>
                <div class="align-left clex_name" v-for="(unit, index) in cex.units" :key="index">
                  {{ translateUnit(unit) }}
                </div>
              </td>
              <td>{{ cex.description }}</td>
              <td>
                <b-button :data-testid="'exc_editBtn-'+cex.cexId"
                          v-b-tooltip.hover
                          title="Edit exception"
                          size="sm"
                          variant="outline-primary"
                          class="exception-action mr-2"
                          @click="editException(cex.cexId)">
                  <feather type="edit-2"></feather>
                </b-button>
                <b-button :data-testid="'exc_delBtn-'+cex.cexId"
                          v-b-tooltip.hover
                          title="Delete exception"
                          size="sm"
                          variant="danger"
                          class="exception-action"
                          @click="deleteException(cex.cexId)">
                  <feather type="trash-2"></feather>
                </b-button>
              </td>
            </tr>
            </tbody>
          </table>
      </div>
      <FloatingButtons v-if="!loading" :buttons="buttons" v-on:floating-button-click="addException">
      </FloatingButtons>
    </div>
    <b-modal v-model="showModal" id="modal-details" size="lg">
      <div class="d-flex flex-column" slot="modal-title">
        <span>Clients</span>
      </div>
      <div>
        <div v-for="cl in this.allClientsDisplayed" :key="cl.clId" class="d-flex w-100">
          <div style="width:10%">
            <strong>id:</strong> {{ cl.clId }}
          </div>
          <div>
            <strong>name:</strong> {{ cl.name }}
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="showModal=false">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import FloatingButtons from '@/components/FloatingButtons.vue';
import translations from '@/translations';

const SkuExceptionsModule = 'SKU_EXCEPTIONS';
const ShipViaExceptionsModule = 'SHIPVIA_EXCEPTIONS';

export default {
  name: 'client-exceptions',
  components: { FloatingButtons },
  created() {
  },
  data() {
    return {
      loading: true,
      showModal: false,
      exceptions: [],
      clientIds: [],
      clients: [],
      isHovered: false,
      limitDisplayedClients: 3,
      batchSizeLimit: 50,
      allClientsDisplayed: [],
    };
  },
  async beforeMount() {
    if (this.isSkuExceptionModule) {
      await this.loadClientExceptions();
    }
    if (this.isShipViaExceptionModule) {
      await this.loadShipViaExceptions();
    }
    await this.mergeClientNames();
  },
  computed: {
    buttons() {
      return {
        save: {
          icon: 'plus',
          text: 'Add',
          color: 'primary',
        },
      };
    },
    isSkuExceptionModule() {
      return this.tagModule === SkuExceptionsModule;
    },
    isShipViaExceptionModule() {
      return this.tagModule === ShipViaExceptionsModule;
    },
  },
  props: {
    tagModule: {
      type: String,
      default: null,
    },
  },
  methods: {
    async mergeClientNames() {
      await this.fetchClientBatches();

      this.exceptions.forEach(exception => {
        const clientData = this.getClientNames(exception.client_ids);
        const displayedArray = clientData.slice(0, this.limitDisplayedClients);
        exception.clients = clientData;
        exception.displayedClients = displayedArray;
      });
    },
    selectItem(clients) {
      this.allClientsDisplayed = clients;
    },
    getClientNames(clientIds) {
      const clientData = [];
      const totalClientIds = clientIds.length;

      for (let i = 0; i < totalClientIds; i++) {
        const client = this.clients.find(c => c.id === clientIds[i]);
        if (client) {
          clientData.push({ clId: clientIds[i], name: client.name });
        }
      }
      return clientData;
    },
    async loadClientExceptions() {
      this.loading = true;
      const uniqueClientIDs = new Set();
      await axios
        .get('v1/skus/exceptions')
        .then(response => {
          response.data.sku_exceptions.forEach(p => {
            p.client_ids.forEach(id => uniqueClientIDs.add(id));
            this.exceptions.push({
              cexId: p.exception_id,
              productType: p.product_type,
              SKU: p.sku,
              description: p.description,
              client_ids: p.client_ids,
              clients: [], // this will be populated by mergeClientNames
              displayedClients: [], // this will be populated by mergeClientNames
              skuDistributions: p.product_type_sku_exceptions,
            });
            this.clientIds = Array.from(uniqueClientIDs);
          });
        })
        .catch(response => {
          this.$noty.error('Unable to load client exceptions');
          console.error(response);
        }).finally(() => { this.loading = false; });
    },
    async fetchClientBatches() {
      if (this.clientIds.length === 0) return;

      const batches = [];

      for (let i = 0; i < this.clientIds.length; i += this.batchSizeLimit) {
        batches.push(this.clientIds.slice(i, i + this.batchSizeLimit));
      }

      const fetchPromises = batches.map(batch => this.fetchClientsByIds(batch));

      await Promise.all(fetchPromises);
    },
    async fetchClientsByIds(clientIDs) {
      if (!clientIDs || clientIDs.length === 0) return;

      const urlSearchParams = new URLSearchParams();
      clientIDs.forEach(clientID => urlSearchParams.append('id', clientID));
      urlSearchParams.append('limit', clientIDs.length);
      urlSearchParams.append('offset', 0);

      this.loading = true;
      await axios.get(`v2/clients?${urlSearchParams.toString()}`)
        .then(response => {
          this.clients.push(...response.data.items);
        }).catch(response => {
          this.$noty.error('Unable to load clients');
          console.error(response);
        }).finally(() => { this.loading = false; });
    },
    async loadShipViaExceptions() {
      const uniqueClientIDs = new Set();
      this.loading = true;
      await axios
        .get('v1/shipvia/exceptions')
        .then(response => {
          response.data.exceptions.forEach(p => {
            p.client_ids.forEach(id => uniqueClientIDs.add(id));
            this.exceptions.push({
              cexId: p.exception_id,
              productType: p.ship_via,
              description: p.description,
              client_ids: p.client_ids,
              clients: [], // this will be populated by mergeClientNames
              displayedClients: [], // this will be populated by mergeClientNames
              shipViaMethod: p.ship_via_method,
              shipViaMethods: p.ship_via_methods,
              units: p.units,
            });
            this.clientIds = Array.from(uniqueClientIDs);
          });
        })
        .catch(response => {
          this.$noty.error('Unable to load ship via exceptions');
          console.error(response);
        }).finally(() => { this.loading = false; });
    },
    editException(exceptionID) {
      if (this.isSkuExceptionModule) {
        this.$router.push({
          name: 'EditClientException',
          params: {
            id: exceptionID,
          },
        });
        return;
      }
      this.$router.push({
        name: 'EditShipViaException',
        params: {
          id: exceptionID,
        },
      });
    },
    async deleteException(exceptionID) {
      const yes = await this.$bvModal.msgBoxConfirm('Are you sure to delete the exception ?');
      if (!yes) {
        return;
      }
      try {
        if (this.isSkuExceptionModule) {
          await axios.delete(`v1/skus/exceptions/${exceptionID}`);
          this.removeExceptionFromUI(exceptionID);
          return;
        }
        await axios.delete(`v1/shipvia/exceptions/${exceptionID}`);
        this.removeExceptionFromUI(exceptionID);
      } catch (error) {
        this.$noty.error('Unable to delete exception', error);
      }
    },
    removeExceptionFromUI(exceptionID) {
      this.exceptions = this.exceptions.filter(exception => exception.cexId !== exceptionID);
    },
    handleInfoHover(hovered) {
      this.isHovered = hovered;
    },
    translateShipViaMethod(shipViaMethodValue) {
      return translations.ship_vias.methods[shipViaMethodValue] ? translations.ship_vias.methods[shipViaMethodValue] : shipViaMethodValue;
    },
    translateUnit(unit) {
      return translations.units[unit] ? translations.units[unit] : unit;
    },
    addException() {
      this.$router.push('/order-management/ship-via-exception/new');
    },
  },
};
</script>
